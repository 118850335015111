/*root*/
$root-images: 'images/bg/';
$root-fonts: 'fonts/';

/* base font-size */
$default-font-size: 16px;

@function em($pixels, $context: $default-font-size) {
  @return #{$pixels/$context}em;
}
