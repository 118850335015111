$sprite: (
	arrow-left: (
		offset-x: 0px,
		offset-y: 0px,
		width: 25px,
		height: 40px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	arrow-right: (
		offset-x: -35px,
		offset-y: 0px,
		width: 25px,
		height: 40px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	arrow: (
		offset-x: -112px,
		offset-y: -36px,
		width: 22px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	facebook-hover: (
		offset-x: -78px,
		offset-y: -50px,
		width: 14px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	facebook: (
		offset-x: 0px,
		offset-y: -86px,
		width: 14px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	horse-hover: (
		offset-x: -70px,
		offset-y: 0px,
		width: 32px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	horse: (
		offset-x: 0px,
		offset-y: -50px,
		width: 32px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	instagram-hover: (
		offset-x: -42px,
		offset-y: -50px,
		width: 26px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
	instagram: (
		offset-x: -112px,
		offset-y: 0px,
		width: 26px,
		height: 26px,
		total-width: 138px,
		total-height: 112px,
		image: 'images/bg/sprite.png'
	),
);


@mixin sprite-size($image) {
	background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
	width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
	height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
	background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
	background-image: url(map-get(map-get($sprite, $image), 'image'));
}

@mixin sprite-image-retina($image) {
	$image-path: map-get(map-get($sprite, $image), 'image');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';

	background-image: url($image-path-retina);
}

@mixin sprite($image, $size: true) {
	@include sprite-image($image);
	@include sprite-position($image);
	background-repeat: no-repeat;

	@if $size {
		@include sprite-width($image);
		@include sprite-height($image);
	}

	@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {

		@include sprite-size($image);
		@include sprite-image-retina($image);

	}
}

