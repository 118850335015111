// phone portrait
@media (max-width: map-get($grid-breakpoints, sm) - 1) {
  body {
    min-width: 320px;
  }
  .logo {
    width: 100px;
    margin-top: -32.5px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .sites {
    padding-left: 0;
    > span {
      display: none;
    }
  }
  .videos {
    .slick-prev,
    .slick-next {
      width: 50px;
      height: 50px;
      bottom: 50%;
      margin-bottom: -45px;
    }
    .slick-dots {
      > li {
        padding: 0 5px;
      }
    }
  }
  .pepsico {
    top: 32px;
    width: 120px;
  }
}
