@font-face {
    font-weight: 400;
    font-family: 'HassGrotte';
    src: url('#{$root-fonts}HassGrotte35.eot');
    src: url('#{$root-fonts}HassGrotte35.eot') format('embedded-opentype'),
         url('#{$root-fonts}HassGrotte35.woff2') format('woff2'),
         url('#{$root-fonts}HassGrotte35.woff') format('woff'),
         url('#{$root-fonts}HassGrotte35.ttf') format('truetype'),
         url('#{$root-fonts}HassGrotte35.svg#HassGrotte35') format('svg');
}
@font-face {
    font-weight: 300;
    font-family: 'HassGrotte';
    src: url('#{$root-fonts}HassGrotte.eot');
    src: url('#{$root-fonts}HassGrotte.eot') format('embedded-opentype'),
         url('#{$root-fonts}HassGrotte.woff2') format('woff2'),
         url('#{$root-fonts}HassGrotte.woff') format('woff'),
         url('#{$root-fonts}HassGrotte.ttf') format('truetype'),
         url('#{$root-fonts}HassGrotte.svg#HassGrotte') format('svg');
}
@font-face {
    font-weight: 400;
    font-family: 'CaveatBrush';
    src: url('#{$root-fonts}CaveatBrushRegular.eot');
    src: url('#{$root-fonts}CaveatBrushRegular.eot') format('embedded-opentype'),
         url('#{$root-fonts}CaveatBrushRegular.woff2') format('woff2'),
         url('#{$root-fonts}CaveatBrushRegular.woff') format('woff'),
         url('#{$root-fonts}CaveatBrushRegular.ttf') format('truetype'),
         url('#{$root-fonts}CaveatBrushRegular.svg#CaveatBrushRegular') format('svg');
}

body {
  font-family: "HassGrotte";
  -webkit-font-smoothing: antialiased;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  display: block;
  -ms-interpolation-mode: bicubic;
  opacity: 1;
  transition: opacity .3s ease;
  &[data-src] {
    opacity: 0;
  }
}
strong,
b {
  font-weight: 700;
}
