.main {
  background-color: #f4b1d0;
}
.intro {
  background: url("#{$root-images}intro.jpg") no-repeat center 0;
  position: relative;
  z-index: 2;
  height: 850px;
  overflow: hidden;
  > div {
    position: relative;
  }
  &__article {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 639px;
  }
  h2 {
    color: $color-red-base;
    font-weight: 300;
    font-size: em(56px);
    line-height: 1.2;
    margin-bottom: 15px;
  }
  p {
    color: $color-blue-base;
    font-size: em(20px);
    margin-bottom: 0;
  }
  &__bottle {
    background: url("#{$root-images}lahev.png") no-repeat;
    position: absolute;
    top: 20px;
    right: -200px;
    z-index: 3;
    width: 329px;
    height: 819px;
	
	&.sk {
		background: url("#{$root-images}lahev_sk.png") no-repeat;
	}
  }
  &__rabbit {
    background: url("#{$root-images}kralik.png") no-repeat;
    position: absolute;
    bottom: -40px;
    left: 50%;
    width: 141px;
    height: 177px;
  }
}
.video {
  background: url("#{$root-images}video.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  margin-top: -211px;
  overflow: hidden;
  > div {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    max-height: 835px!important;
  }
  &__close {
    color: $color-white;
    background-color: $color-red-base;
    font-weight: 700;
    font-size: em(45px);
    line-height: 45px;
    text-align: center;
    position: absolute;
    bottom: 166px;
    right: 0;
    z-index: 5;
    display: none;
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  &__iframe {
    > iframe {
      min-width: 100%;
      min-height: 100%;
    }
  }
  h2 {
    text-indent: -9999px;
    display: block;
    width: 80%;
    margin: 0 auto 30px;
  }
  .button {
    color: $color-white;
    background-color: $color-red-base;
    font-size: em(46px);
    line-height: 105px;
    font-family: "CaveatBrush";
    display: block;
    width: 375px;
    height: 105px;
    border-radius: 50px;
    margin: 0 auto;
    cursor: pointer;
    > span {
      position: relative;
      display: inline-block;
      padding-left: 40px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -13px;
        @include sprite("arrow");
      }
    }
  }
}
.videos {
  background: url("#{$root-images}videos.jpg") no-repeat center center;
  background-size: cover;
  > div {
    text-align: center;
    height: 1092px;
    padding-top: 60px;
    padding-bottom: 45px;
  }
  h2 {
    color: $color-blue-base;
    font-weight: 300;
    font-size: em(80px);
    line-height: 1;
    margin-bottom: 30px;
    > span {
      color: $color-red-base;
      font-weight: 400;
      font-size: 60px;
      font-family: "CaveatBrush";
      display: block;
      transform: rotate(-3deg);
    }
  }
  p {
    color: $color-blue-base;
    font-size: em(20px);
    max-width: 600px;
    margin: 0 auto;
  }
  &__box {
    position: relative;
    padding: 45px 45px 0;
    > span {
      text-indent: -9999px;
      position: absolute;
      top: 0px;
      right: 15px;
      z-index: 3;
      display: block;
      width: 180px;
      height: 98px;
      transform: rotate(10deg);
      cursor: help;
      &.cz {
        background: url("#{$root-images}noise.svg") no-repeat;
      }
      &.sk {
        background: url("#{$root-images}noise-sk.svg") no-repeat;
      }
    }
    &::after {
      content: "";
      background: url("#{$root-images}sova.png") no-repeat;
      position: absolute;
      top: -145px;
      left: 15px;
      z-index: 1;
      width: 116px;
      height: 242px;
    }
    > div {
      background-color: $color-white;
      position: relative;
      z-index: 2;
      padding: 20px 20px 55px;
      border-radius: 20px;
      box-shadow: 0px 0px 15px 0px rgba(19, 56, 147, 0.2);
    }
    h3 {
      color: $color-blue-base;
      font-weight: 400;
      font-size: em(60px);
      font-family: "CaveatBrush";
    }
    .slick-prev {
      background-color: $color-white;
      text-indent: -9999px;
      position: absolute;
      bottom: 180px;
      left: -60px;
      z-index: 1;
      display: block;
      width: 110px;
      height: 110px;
      border: 0;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      transition: background-color .3s ease;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -12.5px;
        @include sprite("arrow-left");
      }
    }
    .slick-next {
      background-color: $color-white;
      text-indent: -9999px;
      position: absolute;
      bottom: 180px;
      right: -60px;
      z-index: 1;
      display: block;
      width: 110px;
      height: 110px;
      border: 0;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      transition: background-color .3s ease;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -12.5px;
        @include sprite("arrow-right");
      }
    }
    .slick-prev:hover,
    .slick-next:hover {
      background-color: #fae1eb;
    }
    .slick-dots {
      bottom: -45px;
      display: table;
      @include center("x");
      > li {
        display: inline-block;
        padding: 0 10px;
        > button {
          background-color: $color-white;
          text-indent: -9999px;
          width: 14px;
          height: 14px;
          padding: 0;
          border: 1px solid #838383;
          border-radius: 50%;
          cursor: pointer;
          transition: all .3s ease;
        }
        &.slick-active {
          > button {
            background-color: $color-red-base;
            border-color: $color-red-base;
          }
        }
      }
    }
  }
}
.nutrition {
  color: $color-blue-base;
  background-color: #eafbff;
  padding-top: 40px;
  &__title {
    color: $color-blue-light;
    font-weight: 300;
    font-size: em(56px);
    line-height: 1.2;
    margin-bottom: 20px;
  }
  &__subtitle {
    color: $color-blue-light;
    font-weight: 300;
    font-size: em(30px);
    line-height: 1.2;
    margin-bottom: 10px;
  }
  p {
    font-size: em(20px);
    margin-bottom: 30px;
  }
  &__table {
    text-align: right;
    width: 100%;
    td {
      padding: 10px 0;
      border-top: 1px solid #cdeff9;
    }
    tr:first-child td {
      border-top: 0;
    }
    td:first-child {
      text-align: left;
    }
  }
}
.svg {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 150px;
}
.svg > div {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: (116.3 / 496 * 100%);
  > svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}
