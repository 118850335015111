@mixin transition ($transition...) {
	transition: $transition $transition-time $transition-type
}
@mixin center ($position) {
	position: absolute;
	@if $position == 'y' {
		top: 50%;
		transform: translateY(-50%);
	}
	@else if $position == 'x' {
		left: 50%;
		transform: translate(-50%);
	}
	@else if $position == 'both' {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
