.header {
  background-color: $color-white;
  > div {
    position: relative;
    height: 100px;
  }
}
.logo {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -46px;
  margin-bottom: 0;
}
.sites {
  position: relative;
  float: right;
  height: 100px;
  padding-left: 130px;
  > span {
    color: #bdbdbd;
    font-size: em(20px);
    line-height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
  }
  > ul {
    margin-top: 35px;
    > li {
      float: left;
      height: 30px;
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      > a {
        text-indent: -9999px;
        display: block;
      }
    }
  }
  &__web {
    @include sprite("horse");
    &:hover {
      @include sprite("horse-hover");
    }
  }
  &__instagram {
    @include sprite("instagram");
    &:hover {
      @include sprite("instagram-hover");
    }
  }
  &__facebook {
    @include sprite("facebook");
    &:hover {
      @include sprite("facebook-hover");
    }
  }
}
