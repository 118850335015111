// phone
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .main {
    background-color: $color-white;
  }
  .intro {
    background: url("#{$root-images}intro-mobile.jpg") no-repeat center bottom;
    height: auto;
    padding-bottom: 350px;
    overflow: visible;
    &__article {
      display: block;
      height: auto;
      padding-top: 0;
    }
    &__bottle {
      background-size: 100% 100%;
      width: 135.6px;
      height: 324.6px;
      top: 100%;
      right: 0;
      margin-top: 100px;
    }
    &__rabbit {
      background-size: 100% 100%;
      top: 100%;
      bottom: auto;
      width: 61px;
      height: 80px;
      margin-top: 280px;
      margin-left: -140px;
    }
    h2 {
      font-size: em(36px);
    }
    p {
      font-size: em(18px);
    }
  }
  .video {
    margin-top: 0;
    h2 {
      margin-bottom: 15px;
    }
    .button {
      font-size: em(21px);
      line-height: 45px;
      width: 180px;
      height: 45px;
      > span {
        padding-left: 30px;
        &::before {
          transform: scale(0.6,0.6);
        }
      }
    }
  }
  .videos {
    > div {
      height: auto;
    }
    h2 {
      font-size: em(40px);
      > span {
        font-size: 30px;
      }
    }
    p {
      font-size: em(18px);
      max-width: 390px;
    }
    &__box {
      padding-right: 0;
      padding-left: 0;
	  margin-top: 30px;
      &::after {
        display: none;
      }
      &::before {
        background-size: 100% 100%;
        top: 15px;
        right: -15px;
        width: 90px;
        height: 49px;
      }
      h3 {
        font-size: em(30px);
      }
    }
    .slick-prev,
    .slick-next {
      width: 75px;
      height: 75px;
      margin-top: -30px;
      &::before {
        transform: scale(0.6,0.6);
      }
    }
    .slick-prev {
      left: -35px;
    }
    .slick-next {
      right: -35px;
    }
  }
  .nutrition {
    padding-bottom: 30px;
    img {
      max-height: 350px;
      margin-bottom: 30px;
    }
    h2 {
      font-size: em(36px);
    }
    &__subtitle {
      font-size: em(24px);
    }
  }
  .video__close {
    bottom: -45px;
  }
}
