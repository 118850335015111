// tablet portrait
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
  .main {
    background-color: $color-white;
  }
  .intro {
    background: url("#{$root-images}intro-tablet.jpg") no-repeat center bottom;
    height: auto;
    padding-bottom: 530px;
    overflow: visible;
    &__article {
      display: block;
      height: auto;
      padding-top: 30px;
    }
    &__bottle {
      background-size: 100% 100%;
      width: 208px;
      height: 491px;
      top: 100%;
      right: 0;
      margin-top: 150px;
    }
    &__rabbit {
      background-size: 100% 100%;
      top: 100%;
      bottom: auto;
      width: 101px;
      height: 135px;
      margin-top: 420px;
      margin-left: -200px;
    }
  }
  .video {
    margin-top: -94px;
    &__close {
      bottom: -45px;
    }
  }
  .videos {
    p {
      font-size: em(18px);
      max-width: 390px;
    }
    &__box {
      padding-right: 0;
      padding-left: 0;
      &::before {
        background-size: 100% 100%;
        top: 15px;
        right: -15px;
        width: 90px;
        height: 49px;
      }
    }
    .slick-prev,
    .slick-next {
      bottom: 150px;
      width: 75px;
      height: 75px;
      &::before {
        transform: scale(0.6,0.6);
      }
    }
    .slick-prev {
      left: -35px;
    }
    .slick-next {
      right: -35px;
    }
  }
  .nutrition {
    h2 {
      font-size: em(40px);
    }
    &__table {
      td {
        padding: 5px 0;
      }
    }
  }
}
